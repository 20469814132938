import React from 'react'
import { LegalPage } from '../components'
 
const LegalPageTemplate = ({ location, pageContext }) => (
  <LegalPage 
    location={location}
    data={pageContext} 
  />
)

export default LegalPageTemplate
